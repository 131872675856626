import React, {useContext, useEffect, useMemo, useState} from "react";
import dbUrl from "../assets/db.sqlite3?url"
import initSqlJs, {Database, SqlJsStatic} from "sql.js";
const resolveWorkletUrl = (name: string): string => {
    return new URL(`/node_modules/sql.js/dist/${name}`, import.meta.url).href
}

export type SqliteConnection = SqliteConnected | SqliteError | SqliteLoading
interface SqliteLoading {
    status: 'loading'
}
interface SqliteConnected {
    status: 'connected'
    db: Database
}
interface SqliteError {
    status: 'error'
    error: unknown
}


// Fetches the database
const useDbData = () => {
    const [dbBytes, setDBBytes] = useState<Uint8Array | null>(null)
    const [error, setError] = useState<unknown | null>(null)

    useEffect(() => {
        const abortController = new AbortController()

        fetch(dbUrl, {
            signal: abortController.signal
        }).then(res => {
                if (abortController.signal.aborted) {
                    return
                }

                if (!res.ok) {
                    setError(new Error(`Got unsuccessful status when fetching DB: ${res.status} ${res.statusText}`))
                    return
                }

                return res.arrayBuffer()
            }
        ).then(arrBuf => {
            if (arrBuf) {
                setDBBytes(new Uint8Array(arrBuf))
            }
        }).catch(
            err => {
                if (abortController.signal.aborted) {
                    return
                }
                setError(err)
            }
        )

        return () => {
            abortController.abort("useDatabase unmounted")
        }
    }, []);

    return {
        dbBytes,
        error,
    }
}

// Initialises SQL.JS
const useSqlJs = () => {
    const [sql, setSql] = useState<SqlJsStatic | null>(null)
    const [error, setError] = useState<unknown | null>(null)

    useEffect(() => {
        let canceled = false

        initSqlJs({
            locateFile: resolveWorkletUrl
        }).then(
            sqlJs => {
                if (canceled) {
                    return
                }

                setSql(sqlJs)
            },
            err => {
                if (canceled) {
                    return
                }

                setError(err)
            }
        )

        return () => {
            canceled = true
        }
    }, [])

    return {
        sql,
        error,
    }
}

const context = React.createContext<SqliteConnection | null>(null)

export const SqliteProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const dbData = useDbData()
    const sqlJs = useSqlJs()

    const conn: SqliteConnection = useMemo(() => {
        if (dbData.dbBytes === null || sqlJs.sql === null) {
            return {
                status: 'loading'
            }
        } else if (dbData.error) {
            return {
                status: 'error',
                error: dbData.error
            }
        } else if (sqlJs.error) {
            return {
                status: 'error',
                error: sqlJs.error
            }
        } else {
            return {
                status: 'connected',
                db: new sqlJs.sql.Database(dbData.dbBytes)
            }
        }
    }, [dbData, sqlJs])

    return (
        <context.Provider value={conn}>
            {children}
        </context.Provider>
    )
}

export const useSqlite = () => {
    const conn = useContext(context)
    if (conn === null) {
        throw new Error("useSqlite must be called within a SqliteProvider.")
    }
    return conn
}

export const useSqliteConnection = () => {
    const conn = useSqlite()
    if (conn.status === "error" || conn.status === 'loading') {
        throw new Error("useSqliteConnection must be called with a connected connection. If you want to check the error or loading state use useSqlite instead.")
    }
    return conn.db
}