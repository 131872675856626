import {useSqlite} from "./components/SqliteProvider";
import {Alert, CircularProgress, Typography} from "@mui/material";
import {lazy, Suspense} from "react";

const Page = lazy(() => import("./components/Page"))

function App() {
    const conn = useSqlite()

    if (conn.status === 'loading') {
        return <LoadingScreen/>
    }
    else if (conn.status === "error") {
        return (
            <Alert severity="error">
                ERROR: Failed to connect to database: {conn.error as any}
            </Alert>
        )
    }

    return (
        <Suspense fallback={<LoadingScreen/>}>
            <Page/>
        </Suspense>
    )
}

const LoadingScreen = () => (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100dvw',
        height: '100dvh',
        flexDirection: 'column'
    }}>
        <CircularProgress size={50}/>
        <Typography variant='subtitle1'>Loading...</Typography>
    </div>
)

export default App
