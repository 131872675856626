import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: "https://2a7d09fae6914e35937869bbc2909a64@o4505594376749056.ingest.sentry.io/4505594382843904",
  integrations: [
    new Sentry.Replay(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})