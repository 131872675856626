import React from 'react'
import ReactDOM from 'react-dom/client'
import './sentry'
import App from './App.tsx'
import {SqliteProvider} from "./components/SqliteProvider"
import './index.css'

if (import.meta.hot) {
    import.meta.hot.accept(() => {
        import.meta.hot?.invalidate();
    })
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
      <SqliteProvider>
          <App />
      </SqliteProvider>
  </React.StrictMode>,
)